const tabs = () => {
  const btns = document.querySelectorAll('.about__tab'),
        contents = document.querySelectorAll('.about__tab-text')

  btns.forEach(btn => {
    btn.addEventListener('click', (e) => {
      let target = e.target.dataset.target
      let tabText = document.querySelector(target)
      disableBtns()
      disableContent()
      tabText.classList.add('show')
      btn.classList.add('active')
    })
  })

  function disableBtns() {
    btns.forEach(btn => {
      btn.classList.remove('active')
    })
  }
  function disableContent() {
    contents.forEach(content => {
      content.classList.remove('show')
    })
  }
}
module.exports = tabs;