const modal = () => {
  const links = document.querySelectorAll('.services__item a');
  const closeBtns = document.querySelectorAll('.modal__close')

  links.forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      let href = e.target.getAttribute('href')
      let dialog = document.querySelector(href)
      dialog.showModal();
      SmoothScroll({stepSize: 0})
      
    })
  })

  closeBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      SmoothScroll({stepSize: 80})
    })
  })

}
module.exports = modal;