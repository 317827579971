import slider from "./modules/slider";
import validate from "./modules/validate";
import tabs from "./modules/tabs";
import modal from "./modules/modal";
import linearAnimations from "./modules/linearAnimations";


window.addEventListener("load", () => {
    tabs()
    slider();
    validate('/[a-zA-Zа-яА-я]/g');
    modal();
    linearAnimations();
    
});
