const linearAnimations = () => {

    let mm = gsap.matchMedia();
    mm.add({
      isMobile: "(max-width: 576px)",
      isTablet: "(max-width: 1280px)",
      isDesktop: "(min-width: 577px)"
    }, (context) => {
      let { isMobile, isDesktop, isTablet } = context.conditions;
  

    const tlShowPromo = gsap.timeline({
        defaults: {
            duration: 1,
            ease: "power4.out"
        },
        paused: true
    });
    tlShowPromo
        .from(".promo__title, .promo__text, .promo__btn", { y: isDesktop ? "150px" : "50px", opacity: 0, stagger: 0.1, clearProps: 'transform' })
        .from(".header", {y: -50, opacity: 0}, '<')
        .from(".promo__img-box", {y: '110%', ease: "elastic.out(1,0.4)", duration: 2}, '<');
    
        
        gsap.utils.toArray(".section .container").forEach((target, i) => {
            gsap.from(target, {
                scrollTrigger: {
                    trigger: target,
                    start: "top 90%",
                    toggleActions: "play none none reverse"
                },
                opacity: 0,
                y: isDesktop ? 150 : 50,
            })
        });

        const tlAboutParallax = gsap.timeline({
            scrollTrigger: {
                trigger: '.about',
                start: 'top bottom',
                end: 'bottom -10%',
                scrub: 1
            }
        })

        tlAboutParallax.fromTo('.about__icons img:nth-child(1)', {y: -200}, {y: 200})
        .fromTo('.about__icons img:nth-child(2)', {y: 100}, {y: -40}, '<')
        .fromTo('.about__icons img:nth-child(3)', {y: -150}, {y: 150}, '<')

        const gradient = {
            start: 5,
            end: 25
        }
        gsap.to(gradient, {
            scrollTrigger: {
                trigger: '.about__dialog',
                start:  isTablet ? 'top center' : 'top 20%',
                end: 'bottom 85%',
                scrub: true,
                preventOverlaps: true,
                invalidateOnRefresh: true,
                onUpdate: () => {
                    gsap.set('.about__text:last-child', {
                        background: `linear-gradient(to bottom, rgba(56, 56, 56, 1) ${gradient.start}%, rgba(56, 56, 56, 0) ${gradient.end}%)`
                    })
                },
            },
            immediateRender: true,
            ease: 'none',
            start: 100,
            end: 125
        })

        gsap.to('.top__box', {
            scrollTrigger: {
                trigger: '.top__box',
                start: 'top top',
                end: 'bottom top',
                scrub: 1,
            },
            y: 50
        })

        gsap.fromTo('.services__box', {
            y: -70
        }, {
            scrollTrigger: {
                trigger: '.services__box',
                start: 'top bottom',
                end: 'bottom top',
                // pin: true,
                scrub: 1,
            },
            y: 70
        })

        gsap.from('.about__img-box', {
            scrollTrigger: {
                trigger: '.about__img-box',
                start: 'top 10%',
                endTrigger: '.about__bottom',
                end: 'bottom bottom',
                // pin: true,
                scrub: 1,
            },
            y: isTablet ? -30 : -50
        })

        gsap.fromTo('.experience__box', {
            y: -50
        }, {
            scrollTrigger: {
                trigger: '.experience__box',
                start: 'top bottom',
                end: 'bottom top',
                // pin: true,
                scrub: 1,
            },
            y: 50
        })
        gsap.fromTo('.need__box', {
            y: -50
        }, {
            scrollTrigger: {
                trigger: '.need__box',
                start: 'top bottom',
                end: 'bottom top',
                // pin: true,
                scrub: 1,
            },
            y: 50
        })

        gsap.to('.preloader', {
            duration: 1,
            autoAlpha: 0,
            onStart: () => {
                window.scrollTo(0, 0);
                gsap.set('html', {scrollBehavior: 'smooth'})
            },
            onComplete: () => {
                tlShowPromo.play(0)
                SmoothScroll({stepSize: 80})
            }
          })

    })
}

module.exports = linearAnimations;