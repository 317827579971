const slider = () => {

    const slider = new Swiper(".experience__slider", {
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 300,
        spaceBetween: '5%',
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        keyboard: true,
        breakpoints: {
            320: {
                slidesPerView: 1,

            },
            769: {
                slidesPerView: 3,
            }
        }
    })

    const buttons = document.querySelectorAll('.experience__btn');
    const weekly = [100, 140, 120, 130, 180];
    const mounthly = [500, 540, 520, 530, 560];
    const prices = document.querySelectorAll('.experience__price span');
    prices.forEach((price, i)=> {
        price.textContent = weekly[i]
    })
    buttons.forEach(function (item, i) {
        item.addEventListener('click', function () {
            buttons.forEach(function (item) {
                item.classList.remove('active')
            })
            item.classList.add('active')
            if(i == 0) {
                prices.forEach((price, i)=> {
                    price.textContent = weekly[i]
                })
            } else {
                prices.forEach((price, i)=> {
                    price.textContent = mounthly[i]
                })
            }
        })
    })
   

    

   
}

module.exports = slider;